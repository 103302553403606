import React from 'react';

const AboutSection = () => {
  return (
    <section className="bg-200 py-16 px-8 flex flex-col md:flex-row items-center justify-center space-y-12 md:space-y-0 md:space-x-12 text-left">
      {/* Left Text Section */}
      <div className="w-full md:w-1/2" style={{ paddingLeft: '150px' }}>
        <h2 className="text-4xl font-bold text-black mb-6 font-genty">
          Let the show begin
        </h2>
        <p className="text-lg text-black mb-6">
          Becky isn’t just the highest paid girl on the blockchain—she’s the muse for all the boys in Matt Furie’s Boy’s Club. Brett, Pepe, Landwolf, and Andy are ready to blow their cash and their wads just to see those sweet pink cheeks jiggle. But Becky isn’t your average girl, she doesn’t strip down for cash, she only starts making it clap when enough of her memecoin is being held. The more VIPs holding her, the wilder she gets.
        </p>
        <p className="text-lg text-black mb-6">
          The boys have scraped every last penny together and are holding as much $Becky as they can afford. They need your help to see what this sexy siren will do next, so grab some tokens, become a VIP, open a private browser and get ready to see heaven.
        </p>

        {/* Telegram Button */}
        <div className="mt-8">
          <a
            href="https://telegram.org" 
            target="_blank" 
            rel="noopener noreferrer"
            className="bg-pink-700 text-white py-2 px-6 rounded-full inline-block hover:bg-pink-600"
          >
            Join the Telegram
          </a>
        </div>
      </div>

      {/* Right Image Section */}
      <div className="w-full md:w-1/2 flex justify-left">
        <img 
          src="https://via.placeholder.com/300" 
          alt="Placeholder" 
          className="w-64 h-auto rounded-lg"
        />
      </div>
    </section>
  );
};

export default AboutSection;
