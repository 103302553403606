import React from 'react';
import XIcon from './images/X-Logo.png';
import TelegramIcon from './images/Telegram-Logo.png';

const Navbar = () => {
  return (
    <nav className="bg-500 h-28 flex items-center justify-between px-64">
      {/* Left: Nia logo */}
      <div className="text-6xl text-pink-900 font-bold italic font-genty">
        Becky
      </div>

      {/* Middle: Navigation Links */}
      <div className="hidden md:flex space-x-8 text-black font-semibold">
        <a href="#about" className="hover:text-900">ABOUT</a>
        <a href="#how-to-buy" className="hover:text-900">HOW TO BUY</a>
        <a href="#roadmap" className="hover:text-900">ROADMAP</a>
      </div>

      {/* Right: Icons */}
      <div className="flex space-x-4 justify-end items-center pr-4">
        {/* Telegram Icon */}
        <img 
          src={TelegramIcon}
          alt="Telegram Icon" 
          className="w-12 h-12"
        />

        {/* X Icon */}
        <img 
          src={XIcon}
          alt="X Icon" 
          className="w-12 h-12"
        />
      </div>
    </nav>
  );
};

export default Navbar;
