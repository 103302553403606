import React from 'react';

const FooterSection = () => {
  return (
    <footer className="w-full mt-8">
    <div className="footer-text">
      <p> 
        <b>$Becky is a memecoin. Memecoins have no inherent value. Never risk money you cannot afford to lose. The price may go up or it may go down.</b>      
      </p>
    </div>
  </footer>
  );
};

export default FooterSection;
