import React from 'react';
import './App.css';
import Navbar from './Navbar';  // Import the Navbar component
import IntroSection from './IntroSection'; // Import the IntroSection
import AboutSection from './AboutSection'; // Import the new AboutSection
import HowToBuySection from './HowToBuySection'; // Import HowToBuySection
import FooterSection from './FooterSection'; // Import FooterSection

function App() {
  return (
    <div className="App">
      {/* Navbar Component */}
      <Navbar />

      {/* Intro Section */}
      <IntroSection />

      {/* About Section */}
      <AboutSection />

      <HowToBuySection />

      <FooterSection />
    </div>
  );
}

export default App;
