import React from 'react';
import beckyImage from './images/beckysol.png';

const IntroSection = () => {
  return (
    <section className="bg-500 min-h-screen flex flex-col items-center justify-center p-8">
      {/* Image and Main Text */}
      <div className="flex flex-col md:flex-row justify-center items-center space-y-8 md:space-y-0 md:space-x-12 mb-8">
        {/* Left Image */}
        <div className="w-full md:w-1/2 flex justify-center">
          <img 
            src={beckyImage} 
            alt="Becky" 
            className="w-[250px] h-auto rounded-lg ml-auto"
          />
        </div>

        {/* Right Text Section */}
        <div className="w-full md:w-1/2 text-center md:text-left">
          <h1 className="text-6xl font-bold text-pink-900 mb-4 font-genty">
            Becky
          </h1>
          <p className="text-2xl font-semibold text-black">
            MEN OF CULTURE<br /> GET YOUR WALLETS READY FOR
          </p>
          <p className="mt-2 text-2xl font-medium text-black">
            The most popular girl on the blockchain
          </p>
          <p className="mt-4 text-md text-black">
            The more you hold the less she holds back
          </p>
        </div>
      </div>

      {/* Wallet Info */}
      <div className="text-center mb-8">
        <div className="bg-pink-700 text-white py-2 px-6 rounded-full inline-block">
          CA: Coming Soon!
        </div>
      </div>

      {/* New Section: Link and Buttons */}
      <div className="text-center mb-8">
        <a href="www.pumpfun.com" className="text-black font-semibold underline hover:text-pink-700">
          Stalk $Becky on PumpFun
        </a>
      </div>

      {/* Buttons Section */}
      <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-8">
        {/* Button 1 */}
        <div className="bg-pink-400 border-4 border-pink-800 text-white font-semibold py-4 px-8 rounded-md w-full md:w-1/3 flex justify-center items-center text-center">
          100% Sexy
        </div>

        {/* Button 2 */}
        <div className="bg-pink-400 border-4 border-pink-800 text-white font-semibold py-4 px-8 rounded-md w-full md:w-1/3 flex justify-center items-center text-center">
          1 Billion Supply
        </div>

        {/* Button 3 */}
        <div className="bg-pink-400 border-4 border-pink-800 text-white font-semibold py-4 px-8 rounded-md w-full md:w-1/3 flex justify-center items-center text-center">
          Fair Launch
        </div>
      </div>
    </section>
  );
};

export default IntroSection;
