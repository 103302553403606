import React from 'react';

const HowToBuySection = () => {
  return (
    <section className="min-h-screen bg-500 text-black flex flex-col items-center justify-center py-12">
      {/* How to Buy Section */}
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center gap-12 mb-16">
        {/* Right Column: Steps (Boxes Moved to the Right) */}
        <div className="md:w-1/2 space-y-6 text-right">
          <h2 className="text-4xl font-bold text-black mb-6 font-genty">How To Buy</h2>
          <p className="text-lg">
            It's simple to get some $Becky in your wallet.
          </p>

          {/* Steps */}
          <div className="space-y-4">
            <div className="bg-pink-500 text-center py-4 rounded-lg shadow-lg w-64 ml-auto">
              1. Put some SOL in your wallet
            </div>
            <div className="bg-pink-500 text-center py-4 rounded-lg shadow-lg w-64 ml-auto">
              2. Go to Raydium
            </div>
            <div className="bg-pink-500 text-center py-4 rounded-lg shadow-lg w-64 ml-auto">
              3. Buy as much $Becky as you can to become a VIP
            </div>
            <div className="bg-pink-500 text-center py-4 rounded-lg shadow-lg w-64 ml-auto">
              4. Hold and watch what happens next!
            </div>
          </div>
        </div>

          {/* Right Column: Image Placeholder */}
          <div className="md:w-1/2 flex justify-left">
            <img 
              src="https://via.placeholder.com/250x500.png?text=Image+Placeholder" 
              alt="Placeholder" 
              className="w-64 h-auto rounded-lg shadow-lg"
            />
          </div>
        </div>

        {/* Roadmap Section */}
        <div className="flex flex-col md:flex-row justify-between items-center gap-12">
          {/* Left Column: Text or other content goes here */}

          {/* Right Column: Image Placeholder */}
          <div className="md:w-1/2 flex md:justify-end justify-center">
            <img 
              src="https://via.placeholder.com/250x500.png?text=Image+Placeholder" 
              alt="Placeholder" 
              className="w-64 h-auto rounded-lg shadow-lg"
            />
          </div>

            {/* Right Column: Roadmap */}
            <div className="md:w-1/2 space-y-6 text-left">
              <h2 className="text-4xl font-bold text-black mb-6 font-genty">Road Map</h2>
              <p className="text-lg">
                Becky loves when you hold her, and she’ll be taking off more and more to show her appreciation to her VIPs.
              </p>
            {/* Roadmap Steps */}
            <div className="space-y-4">
              <div className="bg-pink-500 text-center py-4 rounded-lg shadow-lg w-64">
                <span role="img" aria-label="checkmark">✅</span> 1 Million MC: Twerk vid #1
              </div>
              <div className="bg-pink-500 text-center py-4 rounded-lg shadow-lg w-64">
                <span role="img" aria-label="checkmark">✅</span> 5 Million MC: Second uncensored Twerk vid
              </div>
              <div className="bg-pink-500 text-center py-4 rounded-lg shadow-lg w-64">
                <span role="img" aria-label="checkmark">✅</span> 10 Million MC: X-Rated Twerk vid #3
              </div>
              <div className="bg-pink-500 text-center py-4 rounded-lg shadow-lg w-64">
                <span role="img" aria-label="checkmark">✅</span> 100 Million MC: Shit gets really wild
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowToBuySection;
